require('./bootstrap');
require('svgxuse');
require('slick-carousel');
require('lightbox2');
var jQueryBridget = require('jquery-bridget');
var Masonry = require('masonry-layout');
jQueryBridget( 'masonry', Masonry, $ );
require('imagesloaded');
require('parsleyjs/dist/parsley');
require('parsleyjs/dist/i18n/'+url_lang);
//require('parsleyjs/dist/i18n/en');
require('air-datepicker/dist/js/datepicker');
require('air-datepicker/dist/js/i18n/datepicker.fr');
require('air-datepicker/dist/js/i18n/datepicker.en');
require('jquery-popup-overlay');

$(function() {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    var clickDelay = 0,
        clickDelayTimer = null;

    $('.burger-click-region').on('click', function () {
        if (clickDelayTimer === null) {

            var $burger = $(this);
            $burger.toggleClass('active');
            $burger.parent().toggleClass('is-open');
            $burger.closest('.topbar').toggleClass('active');
            $burger.closest('.header').children('.topbar-center').toggleClass('active');
            $burger.closest('.header').children('.navigation').toggleClass('active');

            if (!$burger.hasClass('active')) {
                $burger.addClass('closing');
            }

            clickDelayTimer = setTimeout(function () {
                $burger.removeClass('closing');
                clearTimeout(clickDelayTimer);
                clickDelayTimer = null;
            }, clickDelay);
        }
    });
    $('.burger-click-region').click();
    setTimeout(function(){
        $('header').removeClass('notransition');
        clickDelay = 400;
    }, 400);

    $('.country .tooglecountry').click(function () {
        var $ct = $(this).parent('.country');
        $ct.toggleClass('active');
        return false;
    });

    $('.country-mobile .tooglecountry').click(function () {
        var $lg = $(this).parent('.country-mobile');
        $lg.toggleClass('active');
        return false;
    });

    $('#newsletter-email').bind('keyup','keydown', function(e){
        if($(this).val().length > 0){
            $(this).closest('form').addClass('show');
        }
        else{
            $(this).closest('form').removeClass('show');
        }
    });

    $(window).on('scroll', function() {
        if ($(this).scrollTop() > 100) {
            $("#back-to-top").fadeIn();
        } else {
            $("#back-to-top").fadeOut();
        }
    });

    $("#back-to-top").css("display", "flex").hide();


    function newContactNewsletter(email){
        displayLoader(false);
        $.ajax({
            url: '/'+url_lang+'/ajax/newsletter',
            type: 'POST',
            data: {'email': email},
            dataType: 'json',
            success: function (data) {
                $('#footer-newsletter .checkbox-rgpd-newsletter').hide();
                $('#footer-newsletter .email-response').text(data.message).show();
                displayLoader(true);
            },
            error: function (xhr, ajaxOptions, thrownError) {
                console.log(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
            }
        });
    }

    $('#footer-newsletter').submit(function()
    {
        email = $('#newsletter-email').val();
        newContactNewsletter(email);
        return false;
    });

    $('.product-controls-print').on('click',function (e) {
        e.preventDefault();
        window.print();
    });

    $('.product-controls-share').on('click',function (e) {
        e.preventDefault();
        $('.social').toggle();
    });

    if (window.matchMedia("(min-width: 992px)").matches) {
        $('.js-call-us').on('click', function () {
            return false;
        });
    }


    if (!banner_hide) {

        $('.close-banner').click(function () {
            $('.message-banner').addClass('hidden');

            $.ajax({
                url: '/banner',
                type: 'POST',
                dataType: 'json',
                success: function (data) {
                },
                error: function (xhr, ajaxOptions, thrownError) {
                    displayLoader(true);
                    console.log(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
                }
            });

        })
    }


});

window.displayLoader = function (bool) {
    if (bool) {
        $("body").removeClass("loading");
    } else {
        $("body").addClass("loading");
    }
};

window.scrollTo = function (target ) {
    if( target.length ) {
        $("html, body").animate({ scrollTop: target.offset().top }, 100);
    }
};